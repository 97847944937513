<template>
  <v-card>
    <StandardCardHeader
      :go-to-link="'admin-warrant-list'"
      :title="'Create'"
      :button-text="'Close'"
      :show-action-button="true"
      :link-button-color="'error'"
      @action="save()"
    />
    <v-row class="px-2 mx-0 pt-3">
      <v-card-text>
        <v-row class="border-bottom-2px-silver text-h6 mb-1">
          <v-col
            cols="12"
            md="6"
            lg="2"
          >
            <div class="text-lg-h6">
              {{ $t('Warrant number') }} <strong>22-05-000002</strong>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <div class="text-lg-h6">
              {{ $t('Warrant code') }} <strong>A12XA23D</strong>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col
            cols="12"
            md="6"
            lg="3"
            class="bordered-column"
          >
            <div class="bordered-column-title">
              {{ $t('Client data') }}
            </div>
            <v-autocomplete
              v-model="company.name"
              dense
              outlined
              :label="$t('Company')"
              class="w-full mb-1"
              autocomplete="off"
              hide-details
            >
            </v-autocomplete>
            <v-text-field
              v-model="company.fullName"
              dense
              outlined
              :label="$t('Full name')"
              class="w-full mb-1"
              hide-details
            >
            </v-text-field>
            <v-text-field
              v-model="company.phone"
              dense
              outlined
              :label="$t('Phone')"
              class="w-full mb-1"
              hide-details
            >
            </v-text-field>
            <v-text-field
              v-model="company.address"
              dense
              outlined
              :label="$t('Address')"
              class="w-full mb-1"
              hide-details
            >
            </v-text-field>
            <v-text-field
              v-model="company.email"
              dense
              outlined
              :label="$t('Email')"
              class="w-full mb-1"
              hide-details
            >
            </v-text-field>
            <v-text-field
              v-model="company.companyIdentificationNumber"
              dense
              outlined
              :label="$t('ID number')"
              class="w-full mb-1"
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
            class="bordered-column"
          >
            <div class="bordered-column-title">
              {{ $t('Job info') }}
            </div>
            <v-text-field
              v-model="job.file"
              dense
              outlined
              :label="$t('File')"
              class="w-full mb-1"
              hide-details
            >
            </v-text-field>
            <v-select
              v-model="job.payment"
              dense
              outlined
              :label="$t('Payment')"
              class="w-full mb-1"
              :items="[$t('Cash'), $t('Žiralno')]"
              hide-details
            >
            </v-select>
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="job.deadline"
                  dense
                  outlined
                  :label="$t('Deadline')"
                  class="w-full mb-1"
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="job.deadline"
                locale="srLatn"
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-model="job.discount"
              dense
              outlined
              :label="$t('Discount')"
              class="w-full mb-1"
              hide-details
            >
            </v-text-field>
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <v-checkbox
                  v-model="byPost"
                  :label="$t('By post')"
                  hide-details
                  dense
                ></v-checkbox>
                <v-checkbox
                  v-model="invoice"
                  :label="$t('Invoice')"
                  hide-details
                  dense
                ></v-checkbox>
                <v-checkbox
                  v-model="isPaid"
                  :label="$t('Paid')"
                  hide-details
                  dense
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-checkbox
                  v-model="addToAccount"
                  :label="$t('Add to account')"
                  hide-details
                  dense
                ></v-checkbox>
                <v-checkbox
                  v-model="clientsMaterial"
                  :label="$t('Client provided material')"
                  hide-details
                  dense
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
            class="bordered-column"
          >
            <div class="bordered-column-title">
              {{ $t('Location data') }}
            </div>
            <v-autocomplete
              v-model="location.createdOn"
              dense
              outlined
              class="w-full mb-1"
              :placeholder="$t('Location of creation')"
              hide-details
            >
            </v-autocomplete>
            <v-autocomplete
              v-model="location.takeOver"
              dense
              outlined
              class="w-full mb-1"
              :placeholder="$t('Takeover location')"
              hide-details
            >
            </v-autocomplete>
            <v-autocomplete
              v-model="location.createdBy"
              dense
              outlined
              class="w-full mb-1"
              :placeholder="$t('Created by')"
              hide-details
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
            class="bordered-column"
          >
            <div class="bordered-column-title">
              {{ $t('Actions') }}
            </div>
            <v-btn
              dark
              class="ma-1 w-full"
              :color="informed ? 'success' : 'error'"
              @click.prevent="toggleInformed()"
            >
              {{ $t('Informed') }}
            </v-btn>
            <v-btn
              dark
              class="ma-1 w-full"
              :color="messageSent ? 'success' : 'error'"
              @click.prevent="toggleMessageSent()"
            >
              {{ $t('Message') }}
            </v-btn>
            <!--            <v-btn-->
            <!--              dark-->
            <!--              class="ma-1 w-full"-->
            <!--              :color="isPaid ? 'success' : 'error'"-->
            <!--              @click.prevent="toggleIsPaid()"-->
            <!--            >-->
            <!--              {{ $t('Paid') }}-->
            <!--            </v-btn>-->
            <v-btn
              dark
              class="ma-1 w-full"
              :color="isDelivered ? 'success' : 'error'"
              @click.prevent="toggleIsDelivered()"
            >
              {{ $t('Delivered') }}
            </v-btn>
            <v-btn
              dark
              tile
              x-large
              class="ma-1 w-full"
              color="dark"
            >
              {{ $t('Save') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-textarea
              rows="3"
              :label="$t('Note')"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row
          v-for="(item, index) in items"
          :key="index"
        >
          <v-col
            cols="12"
            lg="2"
            class="d-inline-flex"
          >
            <v-btn
              class="mx-2"
              fab
              small
              color="error"
              @click.prevent="removeItemsRow(index)"
            >
              <v-icon dark>
                {{ icons.mdiMinus }}
              </v-icon>
            </v-btn>
            <v-text-field
              v-model="item.code"
              :label="$t('Code')"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="item.product"
              :label="$t('Product')"
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="1"
          >
            <v-text-field
              v-model="item.price"
              :label="$t('Price')"
              dense
              outlined
              type="number"
              step="0.01"
              min="0"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="1"
          >
            <v-text-field
              v-model="item.quantity"
              :label="$t('Quantity')"
              dense
              outlined
              type="number"
              step="0.01"
              min="0"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="2"
          >
            <v-autocomplete
              v-model="item.employee"
              :label="$t('Employee')"
              dense
              outlined
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            lg="2"
          >
            <v-autocomplete
              v-model="item.machine"
              :label="$t('Machine')"
              dense
              outlined
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-btn
              class="mx-2 mb-6"
              fab
              small
              color="success"
              hide-details
              @click.prevent="addItemsRow()"
            >
              <v-icon dark>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-row>
    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('Data successfully saved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import {
  mdiArrowDown,
  mdiArrowUp,
  mdiCalendar,
  mdiCircleEditOutline,
  mdiCloseCircle,
  mdiCloseOutline,
  mdiMinus,
  mdiPencilCircle,
  mdiPlus,
} from '@mdi/js'
import axiosIns from '@axios'
import { emailValidator, required } from '@core/utils/validation'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

export default {
  components: { StandardCardHeader },
  data() {
    return {
      dateMenu: false,
      company: {},
      job: {},
      location: {},
      snackbar: false,
      byPost: false,
      invoice: false,
      addToAccount: false,
      clientsMaterial: false,
      informed: false,
      messageSent: false,
      isPaid: false,
      isDelivered: false,
      items: [{}],

      icons: {
        mdiCloseOutline,
        mdiCircleEditOutline,
        mdiPencilCircle,
        mdiCloseCircle,
        mdiCalendar,
        mdiPlus,
        mdiMinus,
        mdiArrowUp,
        mdiArrowDown,
      },
      validator: {
        emailValidator,
        required,
      },
    }
  },

  methods: {
    removeItemsRow(index) {
      this.items.splice(index, 1)
    },
    addItemsRow() {
      this.items.push({})
    },
    toggleInformed() {
      this.informed = !this.informed
    },
    toggleMessageSent() {
      this.messageSent = !this.messageSent
    },
    toggleIsPaid() {
      this.isPaid = !this.isPaid
    },
    toggleIsDelivered() {
      this.isDelivered = !this.isDelivered
    },
    save() {
      const warrant = new FormData()
      warrant.append('warrant_date', this.warrantDate)
      axiosIns({
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: warrant,
        url: this.warrantId ? `/admin/warrants/${this.warrantId}` : '/admin/warrants',
      })
        .then(() => {})
        .catch(() => {})
    },

  },
}

</script>
